import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Warmup/Skill:`}</em>{` Muscle Snatch 4×4`}</p>
    <p>{`Power Snatch 4×4\\@80% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`30-Double Unders`}</p>
    <p>{`5-Power Snatch (115/75)(RX+ 135/95)`}</p>
    <p>{`5-OHS`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The last workout for the CrossFit Open will be announced tonight at
8:00pm at Games.CrossFit.com.  This workout will be Friday’s class
wod.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free class at 9:00 & 10:00am so bring a
friend!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      